import * as yup from 'yup';

export const patientValidationSchma = {
  firstname: yup
    .string()
    .min(4, 'Too Short Firstname!')
    .max(30, 'Too Long Firstname!')
    .required('Firstname is required!'),
  lastname: yup
    .string()
    .min(4, 'Too Short Lastname!')
    .max(30, 'Too Long Lastname!')
    .required('Lastname is required!'),
  email: yup
    .string()
    .email('Invalid email!')
    .required('Email address is required!'),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Invalid phone number')
    .required('Contact no is required!'),
  fileID: yup.string().required('FileId is required!'),
  address: yup.string().required('Address is required!')
};
