import { EventClickArg } from '@fullcalendar/core';
import FullCalendar from 'components/base/FullCalendar';
import CalendarEventModal from 'components/modals/CalendarEventModal';
import events from 'data/calendarEvents';
import * as formik from 'formik';
import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { HANDLE_SELECT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import CalendarHeader from './calendar/CalendarHeader';
import './calendar/calendar.css';
import { errorHandler } from 'helpers/response-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';

const PatientDailyProgress = () => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const { calendarDispatch } = useCalendarContext();
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    getMealByDateRange();
  }, []);

  const getMealByDateRange = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/getpatientmeal`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            patient: id
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.meals;
      setMeals(data);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          selectedEvent: info.event
        }
      });
    }
  };

  return (
    <Row>
      <Col xl={12}>
        <CalendarProvider>
          <div>
            <CalendarHeader />
            <div className="mt-6 mb-9">
              <FullCalendar
                height={800}
                select={info => {
                  calendarDispatch({
                    type: HANDLE_SELECT,
                    payload: info
                  });
                }}
                events={meals}
                eventClick={handleEventClick}
              />
            </div>
            <CalendarEventModal />
          </div>
        </CalendarProvider>
      </Col>
    </Row>
  );
};

export default PatientDailyProgress;
