import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import ResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
import App from 'App';

import PatientList from 'pages/clinic/patient/PatientList';
import AddPatient from 'pages/clinic/patient/AddPatient';
import EditPatient from 'pages/clinic/patient/EditPatient';

import Clinics from 'pages/superadmin/clinic/Clinics';
import SAAddClinic from 'pages/superadmin/clinic/AddClinic';
import SAEditClinic from 'pages/superadmin/clinic/EditClinic';

import UserLeads from 'pages/user/dashboard/UserLeads';
import UserEditLead from 'pages/user/dashboard/EditLead';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '',
        element: (
          <MainLayoutProvider>
            {' '}
            <MainLayout />{' '}
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'clinic',
            children: [
              {
                path: '',
                element: <Navigate to="patient/list" replace />
              },
              {
                path: 'patient/list',
                element: <PatientList />
              },
              {
                path: 'patient/add',
                element: <AddPatient />
              },
              {
                path: 'patient/edit/:id',
                element: <EditPatient />
              }
            ]
          },
          {
            path: 'superadmin',
            children: [
              {
                path: '',
                element: <Navigate to="clinic/list" replace />
              },
              {
                path: 'clinic/list',
                element: <Clinics />
              },
              {
                path: 'clinic/add',
                element: <SAAddClinic />
              },
              {
                path: 'clinic/edit/:id',
                element: <SAEditClinic />
              }
            ]
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                element: <Navigate to="crm/leads" replace />
              },
              {
                path: 'crm/leads',
                element: <UserLeads />
              },
              {
                path: 'crm/leads/edit/:id',
                element: <UserEditLead />
              }
            ]
          }
        ]
      },
      {
        path: '/auth/',
        children: [
          {
            path: 'login',
            element: <SimpleSignIn />
          },
          {
            path: 'reset/:token',
            element: <ResetPassword />
          },
          {
            path: 'logout',
            element: <SimpleSignOut />
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />
          }
        ]
      },

      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
