import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row, Tab, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';
import { patientValidationSchma } from 'static/Patient';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import UploadPatientReport from './UploadPatientReport';
import PatientDailyProgress from './PatientDailyProgress';
import UploadPrescription from './UploadPrescription';

const EditPatient = () => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const genderArray = ['male', 'female'];
  const [patient, setPatient] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    gender: '',
    fileID: '',
    birthdate: ''
  });
  const schema = yup.object().shape(patientValidationSchma);

  useEffect(() => {
    getPatient();
  }, []);

  const getPatient = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.patient;
      console.log(data.birthdate, 'moment(data.birthdate).toString()');
      setPatient({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.phone,
        address: data.address,
        gender: data.gender,
        fileID: data.fileID,
        birthdate: moment(data.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmit = async (values: any) => {
    const data = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      address: values.address,
      gender: values.gender,
      fileID: values.fileID,
      birthdate: values.birthdate
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/clinic/patient/list');
  };

  return (
    <Card className="col-12">
      <Card.Header>
        <div className="d-sm-flex justify-content-between">
          <h2>Edit Patient</h2>
        </div>
      </Card.Header>
      <Card.Body>
        <Tab.Container id="basic-tabs-example" defaultActiveKey="profile">
          <Nav variant="underline" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="reports">Reports</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="daily_track">Daily Progress</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="diet">Suggested Diet</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <Row>
                <Col xl={12}>
                  <Formik
                    validationSchema={schema}
                    onSubmit={values => onSubmit(values)}
                    initialValues={{
                      firstname: '',
                      lastname: '',
                      email: '',
                      phone: '',
                      address: '',
                      gender: '',
                      fileID: '',
                      birthdate: ''
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      dirty,
                      setValues
                    }) => {
                      useEffect(() => {
                        setValues(patient);
                      }, [patient]);
                      return (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Row className={classNames('g-3', 'mb-6')}>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="firstname"
                                  value={values.firstname}
                                  onChange={handleChange}
                                  isValid={
                                    touched.firstname && !errors.firstname
                                  }
                                  isInvalid={
                                    touched.firstname && !!errors.firstname
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.firstname}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="lastname"
                                  value={values.lastname}
                                  onChange={handleChange}
                                  isValid={touched.lastname && !errors.lastname}
                                  isInvalid={
                                    touched.lastname && !!errors.lastname
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.lastname}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  isValid={touched.email && !errors.email}
                                  isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.email}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Contact</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  value={values.phone}
                                  onChange={handleChange}
                                  isValid={touched.phone && !errors.phone}
                                  isInvalid={touched.phone && !!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.phone}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Civil ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="fileID"
                                  value={values.fileID}
                                  onChange={handleChange}
                                  isValid={touched.fileID && !errors.fileID}
                                  isInvalid={touched.fileID && !!errors.fileID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.fileID}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Birthdate</Form.Label>
                                <Form.Control
                                  type="date"
                                  name="birthdate"
                                  value={values.birthdate}
                                  onChange={handleChange}
                                  isValid={
                                    touched.birthdate && !errors.birthdate
                                  }
                                  isInvalid={
                                    touched.birthdate && !!errors.birthdate
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.birthdate}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                  name="gender"
                                  value={values.gender}
                                  onChange={handleChange}
                                  isValid={touched.gender && !errors.gender}
                                  isInvalid={touched.gender && !!errors.gender}
                                >
                                  <option value=""> -- Select -- </option>
                                  {genderArray.length
                                    ? genderArray.map((gender, key) => (
                                        <option value={gender} key={key}>
                                          {' '}
                                          {gender.charAt(0).toUpperCase() +
                                            gender.slice(1)}{' '}
                                        </option>
                                      ))
                                    : ''}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.gender}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={12} md={12}>
                              <Form.Group className="mb-2">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                  rows={4}
                                  isValid={touched.address && !errors.address}
                                  isInvalid={
                                    touched.address && !!errors.address
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {' '}
                                  {errors.address}{' '}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <div className="text-end">
                              <Button
                                variant="phoenix-primary"
                                className="me-2 px-6"
                                onClick={navigateBack}
                              >
                                Cancel
                              </Button>
                              <Button type="submit" variant="primary">
                                Update
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="reports">
              <UploadPatientReport />
            </Tab.Pane>
            <Tab.Pane eventKey="daily_track">
              <PatientDailyProgress />
            </Tab.Pane>
            <Tab.Pane eventKey="diet">
              <UploadPrescription />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default EditPatient;
